export default (theme) => ({
  dialogContent: {
    width: 464,
    padding: 0,
    marginTop: 16,
  },

  bindWrapperTips: {
    width: 464,
    height: 40,
    background: "#F7F9FC",
    position: "absolute",
    left: 0,
    fontSize: 14,
    lineHeight: "20px",
    color: "#50555B",
    fontStyle: "normal",
    fontWeight: "normal",
    marginTop: 16,
    display: "flex",
    alignItems: "center",
    padding: "0 24px",
  },

  bindWrappers: {
    width: "100%",
    padding: "24px",
  },

  bindWrapper: {
    position: "relative",
    width: 416,
    height: 104,
    background: "rgba(51, 117, 224, 0.05)",
    borderRadius: 2,
    display: "flex",
    alignItems: "center",
    color: "#242B32",
    marginTop: 16,
    padding: "0 0 0 120px",
    "&.email": {
      backgroundImage: `url(${require("@/assets/user_center/bind-email.png")})`,
      backgroundSize: "72px 72px",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "24px center",
    },

    "&.ga": {
      backgroundImage: `url(${require("@/assets/user_center/bind-ga.png")})`,
      backgroundSize: "72px 72px",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "24px center",
    },

    "&.mobile": {
      backgroundImage: `url(${require("@/assets/user_center/bind-mobile-bg.png")})`,
      backgroundSize: "48px 48px",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "bottom right",
    },
  },

  icon: {
    color: theme.palette.primary.main,
    position: "absolute",
    right: 20,
  },
  iconSuccess: {
    color: "#01AC8F",
  },
});
