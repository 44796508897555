export default (theme) => ({
  finance_title_con: {
    minWidth: 1200,
    maxWidth: 1680,
    margin: "0px auto",
  },
  finance_title_box: {
    background: theme.palette.black[800],
    padding: "55px 0 0",
    borderTop: `1px solid ${theme.palette.secondary.contrastText}`,
    margin: "0 0 24px",
    "& .content": {
      padding: "0 40px",
    },
  },
  finance_title: {
    margin: "0 0 40px 0",
    alignItems: "center",
    display: "flex",
    color: theme.palette.common.white,
    height: 112,
  },
  header: {
    display: "flex",
    margin: "0 0 32px",
    "& ul": {
      display: "flex",
      flex: 1,
      "& li": {
        ...theme.typography.body1,
        flex: 1,
        margin: "0 24px 0 0",
        padding: "16px 32px 0",
        height: 144,
        borderRadius: "8px 8px 4px 4px",
        boxShadow: "0px 2px 24px rgba(51, 117, 224, 0.1)",
        background: theme.palette.common.white,
        cursor: "pointer",
        lineHeight: "32px",
        fontSize: 16,
        "& em": {
          // color: theme.palette.primary.main,
          display: "block",
          height: 32,
          fontWeight: "bold",
        },
        "& strong": {
          color: theme.palette.common.text,
          fontSize: 24,
          display: "block",
          margin: "8px 0",
          height: 32,
        },
        "& span": {
          color: theme.palette.grey[800],
        },
        "&:last-child": {
          margin: "0",
        },
      },
      "& li.active": {
        borderBottom: `4px solid ${theme.palette.primary.main}`,
        "& em": {
          color: theme.palette.primary.main,
        },
      },
      "& li:hover": {
        borderBottom: `4px solid ${theme.palette.primary.main}`,
        "& em": {
          color: theme.palette.primary.main,
        },
      },
    },
  },
  header1: {
    margin: "56px 0 0",
    color: theme.palette.grey[500],
    fontSize: 16,
    "& ul": {
      display: "flex",
      "& li": {
        height: 56,
        minWidth: 80,
        padding: 5,
        display: "flex",
        alignItems: "center",
        margin: "0 24px 0 0",
        justifyContent: "center",
        cursor: "pointer",
        borderBottom: `3px solid transparent`,
        "&.active": {
          color: theme.palette.common.white,
          borderColor: theme.palette.primary.main,
        },
        "&:hover": {
          color: theme.palette.common.white,
        },
      },
    },
  },
  menu_hover: {
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  info: {
    ...theme.typography.body2,
    flex: 1,
    "& span": {
      color: theme.palette.grey[200],
      display: "block",
      fontSize: 18,
      lineHeight: "24px",
    },
    "& p": {
      fontSize: 16,
      lineHeight: "24px",
      display: "flex",
      alignItems: "center",
      color: theme.palette.common.white,
      "& i": {
        color: theme.palette.primary.light,
        transform: "rotate(90deg)",
        margin: "0 0 0 4px",
        cursor: "pointer",
      },
      "& em": {
        color: theme.palette.primary.light,
        cursor: "pointer",
      },
    },
    "& strong": {
      fontSize: 32,
      height: 64,
      display: "flex",
      fontWeight: 500,
      alignItems: "center",
      color: theme.palette.common.white,
      "& i": {
        margin: "0 0 0 10px",
        fontSize: 24,
      },
    },
  },
  btn: {
    height: 40,
    minWidth: 96,
    margin: "0 0 0 8px",
    borderColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: "1rem",
  },
  "@media screen and (min-width: 1440px)": {
    finance_title_box: {
      "& .content": {
        padding: "0 120px",
      },
    },
  },
});
