// collect undefined locale
import React from "react";
import { injectIntl, FormattedHTMLMessage, FormattedMessage } from "react-intl";

/**
 * @param {string} id 语言包key "key"
 * @param {object} values key中的变量 {rate: 50, token: "BTC"}
 * @param {string} tagName 标签类型 span, p, div
 */
const MessageRC = (props) => {
  const { intl, id, values, tagName, className } = props;
  // localStorage存储未翻译的key
  const undefinedLocale =
    JSON.parse(localStorage.getItem("undefinedLocale")) || {};
  if (window.appLocale.messages[id]) {
    // if (window.WEB_LOCALES_ALL[id]) {
    delete undefinedLocale[id];
  } else {
    undefinedLocale[id] = id;
  }
  localStorage.setItem("undefinedLocale", JSON.stringify(undefinedLocale));

  // react-intl "^2.4.0"
  if (tagName) {
    // 支持id中带有html标签
    const Comp = tagName;
    const translated = intl.formatHTMLMessage({ id: id }, values);
    return (
      <Comp
        id={id}
        className={className}
        dangerouslySetInnerHTML={{ __html: translated }}
      ></Comp>
    );
    // return <FormattedHTMLMessage id={id} values={values} tagName={tagName} />;
  }
  return intl.formatMessage({ id: id }, values);

  // react-intl "^5.8.8" FormattedHTMLMessage被去除了，FormattedMessage能够兼容以上两种情况
  // if (tagName) {
  //   return <FormattedMessage id={id} values={values} tagName={tagName} />;
  // }
  // return <FormattedMessage id={id} values={values} />;
};

export default injectIntl(MessageRC);
