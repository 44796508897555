// 活动账户资产
import React, { useEffect, useState, useCallback } from "react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { injectIntl } from "react-intl";
import styles from "./style";
import helper from "../../utils/helper";
import FinanceHeader from "../public/finance_header";
import { Table, Iconfont, message } from "../../lib";
import { CircularProgress } from "@material-ui/core";
import TransferModal from "../public/transfer_modal";

const ActivityAccountRC = (props) => {
  const {
    classes,
    intl,
    functions,
    total_asset,
    rates,
    hidden_balance,
    userinfo,
    dispatch,
    account_activity_index,
    ...otherProps
  } = props;
  const [unit, setUnit] = useState("");
  const [financeRate, setFinanceRate] = useState(["", "--"]);
  const [datas, setData] = useState([]);
  const [first, setFirst] = useState(true);
  const [amount, setAmount] = useState("");
  const [info, setInfo] = useState({
    orgId: "",
    time: "",
    total: 0,
    userTotal: 0,
    isBuy: false,
    tokenId: "",
    userUnderweight: {},
  });
  const [avai, setAvai] = useState("");
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [currentToken, setToken] = useState("USDT");
  const title = {
    HBC: {
      available: "可出售",
      locked: "锁仓中",
      underweightAvailable: "可申请流通",
    },
    USDT: {
      available: "可用",
      locked: "冻结",
    },
  };
  const hide_str = "********";
  const [refresh, setRefresh] = useState(false);
  const column = [
    {
      title: intl.formatMessage({
        id: "币种",
      }),
      key: "tokenName",
      render: (text, record) => {
        return (
          <div className={classes.tokenIcon}>
            {record.iconUrl ? (
              <img src={record.iconUrl} />
            ) : (
              <em className="noIcon" />
            )}
            <p>
              <span>{record.tokenName}</span>
              <span>{record.tokenFullName}</span>
            </p>
          </div>
        );
      },
    },
    {
      title: intl.formatMessage({
        id: "可用",
      }),
      key: "available",
      render: (text, record) => {
        return (
          <p className="itemp">
            {hidden_balance ? hide_str : helper.digits(text, 8)}
          </p>
        );
      },
    },
    {
      title: intl.formatMessage({
        id: "冻结",
      }),
      key: "locked",
      render: (text, record) => {
        return (
          <p className="itemp">
            {hidden_balance ? hide_str : helper.digits(text, 8)}
          </p>
        );
      },
    },
    {
      title: intl.formatMessage({
        id: (props.total_asset ? total_asset.unit : "") + "估值",
      }),
      key: "usdtValue",
      render: (text, record) => {
        const btcRates = helper.currencyValue(
          props.rates,
          text,
          props.total_asset ? props.total_asset.unit : null
        );
        return (
          <div>
            <div>
              {props.hidden_balance
                ? hide_str
                : !text && text != 0
                ? "--"
                : helper.digits(text, 2)}
              {` ≈ ${props.hidden_balance ? "" : btcRates[0]}${
                props.hidden_balance ? hide_str : btcRates[1]
              }`}
            </div>
          </div>
        );
      },
    },
    {
      title: intl.formatMessage({
        id: "操作",
      }),
      key: "tokenId",
      render: (text, record) => {
        return (
          <a
            onClick={() => {
              setToken(text);
              setModal(true);
            }}
          >
            {intl.formatMessage({ id: "划转" })}
          </a>
        );
      },
    },
  ];

  const getList = useCallback(() => {
    if (!userinfo.userId) {
      return;
    }
    dispatch({
      type: "finance/commonReq",
      payload: {},
      url: "activity_account_list",
      success: (res) => {
        setData(res);
        const arr = res.filter((list) => list.tokenName == unit);
        if (arr && arr[0]) {
          setAvai(arr[0].available);
        }
        setFirst(false);
      },
      fail: (code, msg) => {
        msg && message.error(msg);
      },
    });
  }, [dispatch, unit, userinfo.userId]);

  function setTotal() {
    setAmount(Math.min(info.userTotal, info.total));
  }

  useEffect(() => {
    const cRate = helper.currencyValue(
      rates,
      total_asset ? total_asset.activeCoinAsset : 0,
      total_asset ? total_asset.unit : 0
    );
    setFinanceRate(cRate);
  }, [rates, total_asset]);

  useEffect(() => {
    getList();
  }, [getList, unit, userinfo]);

  // 查询账户信息
  useEffect(() => {
    function getAccountInfo() {
      if (!userinfo.userId) {
        return;
      }
      dispatch({
        type: "finance/commonReq",
        payload: {},
        url: "underweight_info",
        success: (res) => {
          setInfo(res);
          setUnit(res.tokenId);
        },
        fail: (code, msg) => {
          msg && message.error(msg);
        },
      });
    }
    getAccountInfo();
  }, [dispatch, userinfo]);

  // 查询资产
  useEffect(() => {
    function getFinance() {
      dispatch({
        type: "layout/getTotalAsset",
        payload: {
          unit: "usdt",
        },
      });
    }
    getFinance();
  }, [dispatch]);

  useEffect(() => {
    setRefresh(!refresh);
  }, [total_asset, hidden_balance]);

  return (
    <div className={classNames(classes.list, classes.finance_list)}>
      <FinanceHeader
        tab="activity"
        functions={functions}
        hidden_balance={hidden_balance}
        dispatch={dispatch}
        rates={rates}
        total_asset={total_asset}
        userinfo={userinfo}
      />
      <div className={classes.content}>
        <div className={classes.wallet}>
          <div className={classes.wallet_total}>
            <em>
              {intl.formatMessage({ id: "资产折合" })}:{" "}
              {total_asset &&
              total_asset.activeCoinAsset &&
              rates &&
              rates[total_asset.unit]
                ? hidden_balance
                  ? hide_str
                  : helper.digits(
                      total_asset.activeCoinAsset,
                      (total_asset ? total_asset.unit : "") == "BTC" ? 8 : 2
                    )
                : ""}{" "}
              {total_asset ? total_asset.unit : ""}
              <span>
                {" "}
                {total_asset &&
                total_asset.activeCoinAsset &&
                rates[total_asset.unit]
                  ? `≈ ${financeRate[0]}${
                      hidden_balance ? hide_str : financeRate[1]
                    }`
                  : ""}
              </span>
            </em>
          </div>
          <div>
            {userinfo.userId ? (
              first ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: 300,
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <Table
                  data={datas}
                  titles={column}
                  hasMore={false}
                  refresh={refresh}
                  className={classes.activity_table}
                />
              )
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      <TransferModal
        open={modal}
        source_type={account_activity_index}
        target_type={0}
        onCancel={() => {
          setModal(false);
        }}
        submitCallback={() => {
          getList();
        }}
        token_id={currentToken}
        {...props}
      />
    </div>
  );
};

export default withStyles(styles)(injectIntl(ActivityAccountRC));
