import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle as MuiDialogTitle,
  Button,
  Typography,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Iconfont from "@/lib/components/iconfont";
import route_map from "@/config/route_map";
import { injectIntl } from "react-intl";

import style from "./force_bind_modal.style";

const DialogTitle = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function ForceBindModal(props) {
  const { classes, userinfo } = props;

  const [showBindModal, setShowBindModal] = useState(props.open);

  useEffect(() => {
    setShowBindModal(props.open);
  }, [props.open]);

  const hideBindModal = () => {
    sessionStorage.setItem("last_show_time", new Date().getTime());
    props.onClose && props.onClose();
  };

  return (
    <Dialog open={showBindModal} disableBackdropClick disableEscapeKeyDown>
      <DialogTitle onClose={hideBindModal}>
        {props.intl.formatMessage({ id: "安全提示" })}
        <p className={classes.bindWrapperTips}>
          {props.intl.formatMessage({
            id: "您的账户安全等级较低，请启用以下两项安全验证",
          })}
        </p>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.bindWrappers}>
          <a
            key="ga"
            className={classnames(classes.bindWrapper, "ga")}
            href={route_map.user_bind + "/ga"}
          >
            {props.intl.formatMessage({ id: "谷歌验证" })}
            {userinfo && userinfo.bindNav && userinfo.bindNav.needBindGa ? (
              <Iconfont
                type="arrowRight"
                size={30}
                className={classes.icon}
              ></Iconfont>
            ) : (
              <Iconfont
                type="check"
                size={30}
                className={classnames(classes.icon, classes.iconSuccess)}
              ></Iconfont>
            )}
          </a>
          {
            <a
              key="email"
              className={classnames(classes.bindWrapper, "email")}
              href={route_map.user_bind + "/email"}
            >
              {props.intl.formatMessage({ id: "邮箱验证" })}
              {userinfo &&
              userinfo.bindNav &&
              userinfo.bindNav.needBindEmail ? (
                <Iconfont
                  type="arrowRight"
                  size={30}
                  className={classes.icon}
                ></Iconfont>
              ) : (
                <Iconfont
                  type="check"
                  size={30}
                  className={classnames(classes.icon, classes.iconSuccess)}
                ></Iconfont>
              )}
            </a>
          }
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={hideBindModal} variant="text" color="primary">
          {props.intl.formatMessage({ id: "下次绑定" })}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default withStyles(style)(injectIntl(ForceBindModal));
